<template>
  <div class="playtables-table m-2">
    <div class="players-display my-2">
      <!-- begin players row -->
      <div class="playername">{{ table.players[0] | playerLabel }}</div>
      <div class="versus">vs</div>
      <div class="playername">{{ table.players[1] | playerLabel }}</div>
      <!-- end players row-->
    </div>
    <div id="status" class="my-2">
      {{ $t(gameStatus, winnerData) }}
    </div>
    <div v-if="isManager" class="my-2">
      <button class="btn"
        :class="classGameSetBtn"
        :disabled="$store.state.event.round.status != 'running'"
        @click="$emit('gameset', table.seq)">
          {{ $t('label.gameset') }}
      </button>
    </div>
  </div>
</template>
<script>
  
export default {
  name: "PlayTable",
  props: {
    table: {
      type: Object,
      default: () => ({
        seq: 0,
        players: [
          { seq: 0, name: "player 1" },
          { seq: 0, name: "player 2" },
        ]
      })
    },
    isManager: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    gameStatus() {
      return !this.table.result ? 'processing'
            : this.table.result.isDraw ? 'draw'
            : this.table.result.winner ? 'won'
            : 'processing'
    },
    classGameSetBtn() {
      let isGameSet = this.table.result && (this.table.result.isDraw || this.table.result.winner)
      return {
        "btn-primary": !isGameSet,
        "btn-secondary": isGameSet,
      }
    },
    winnerData() {
      return this.gameStatus == 'won' ? {
        winner: this.table.result.winner,
        name: this.table.players.find(e => e.seq === this.table.result.winner).name
      } : null
    },
  },
  filters: {
    playerLabel(player) {
      return `[${player.seq}] ${player.name}`
    },
  },
}
</script>
<style scoped>
.playtables-table {
  border: 2px solid silver;
  text-align: center;
}

.playtables-table .playername {
  font-size: 1.6em;
  font-weight: bold;
}

.playtables-table .versus {
  font-size: 1.6em;
}

@media (min-width: 768px) {
  .playtables-table .players-display {
    display: grid;
    grid-template-columns: 1fr 5em 1fr;
    grid-row-gap: 0.8em;
    text-align: center;
  }
}
</style>
<i18n>
{
  "en": {
    "label": {
      "gameset": "Game Set"
    },
    "processing": "Processing",
    "draw": "Draw",
    "won": "[{winner}] {name} Won"
  },
  "ja": {
    "label": {
      "gameset": "決着"
    },
    "processing": "進行中",
    "draw": "引き分け",
    "won": "[{winner}] {name} が勝利"
  }
}
</i18n>
