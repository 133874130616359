const iconAttrData = {
  data() {
    return {
      iconAttr: {
        success: {
          icon: "check-circle",
          class: "text-success",
        },
        warning: {
          icon: "exclamation-triangle",
          class: "text-warning",
        },
        danger: {
          icon: "times-circle",
          class: "text-danger",
        },      
      }
    }
  }
}

const dialogSkip = {
  data() {
    return {
      skipDialog: false,
    }
  },
  methods: {
    enableSkipDialog(propName) {
      this.$store.dispatch('setConfig', {
        prop: propName,
        value: true,
      })
    }
  }
}

export { iconAttrData, dialogSkip }
