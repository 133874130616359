<template>
  <base-modal :id="id" class="confirmRoundOver">
    <template #header>
      <h4 class="modal-title">{{ $t('label.confirm') }}</h4>
    </template>

    <template #default>
      <template v-if="processing == 0">
        <p>
          <font-awesome-icon v-bind="iconAttr.success" />
          {{ $t('message.complete') }}
        </p>
        <skip-dialog-input v-model="skipDialog">
          {{ $t('message.skip') }}
        </skip-dialog-input>
      </template>
      <template v-else>
        <p>
          <font-awesome-icon v-bind="iconAttr.warning" />
          {{ $t('message.remaining', { games: $tc('unit.game', processing) }) }}
        </p>
        <p class="note warning p-2" v-html="$t('message.caution')">
        </p>
      </template>
    </template>

    <template #footer>
      <button class="btn btn-secondary" data-dismiss="modal">{{ $t('label.back') }}</button>
      <button class="btn btn-primary" data-dismiss="modal" @click="clickProceed">{{ $t('label.proceed') }}</button>
    </template>
  </base-modal>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapGetters } = createNamespacedHelpers('event')
import BaseModal from '@/components/BaseModal.vue'
import SkipDialogInput from '@/components/SkipDialogInput.vue'
import { iconAttrData, dialogSkip } from '@/mixins'

export default {
  name: "ConfirmRoundOverModal",
  components: {
    BaseModal,
    SkipDialogInput,
  },
  mixins: [ iconAttrData, dialogSkip ],
  props: ["id"],
  computed: {
    ...mapState({
      tables: state => state.table.list,
    }),
    ...mapGetters({
      processing: 'processingCount',
    }),
  },
  methods: {
    clickProceed() {
      if(this.skipDialog) {
        this.enableSkipDialog('skipRoundOverSafety')
      }
      this.$emit('confirm')
    },
  },
}
</script>
<style scoped>
.confirmRoundOver .note.warning {
  background-color: #ffffcc;
  border-left: 6px solid #ffeb3b;
}
</style>
<i18n>
{
  "en": {
    "message": {
      "complete": "All games were finished in this round!",
      "remaining": "This round still has {games} in progress.",
      "caution": "<b>Caution:</b> if this round is over immidiately, all players in progressing game are lose the game.",
      "skip": "Don't show this dialog later if all games in round are finished"
    }
  },
  "ja": {
    "message": {
      "complete": "このラウンドの全ての試合が終了しました。",
      "remaining": "このラウンドではまだ{games}が進行中です。",
      "caution": "<b>注意:</b> このラウンドを直ちに終了すると、進行中の試合の全ての選手が敗北となります。",
      "skip": "全試合終了時、このダイアログを表示しない"
    }
  }
}
</i18n>
