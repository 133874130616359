<template>
  <div class="custom-control custom-checkbox">
    <input type="checkbox" class="custom-control-input" id="skipButton"
      :checked="checked"
      @change="$emit('change', $event.target.checked)"
    >
    <label class="custom-control-label" for="skipButton">
      <slot> {{ $t('message.skipDialog') }} </slot>
    </label>
  </div>
</template>
<script>
export default {
  name: 'SkipDialogInput',
  model: {
    prop: 'checked',
    event: 'change'
  },
  props: ['checked'],
}
</script>
