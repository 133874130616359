<template>
  <base-modal :id="id" class="confirmRoundAdvance">
    <template #header>
      <h4 class="modal-title">{{ $t('label.confirm') }}</h4>
    </template>

    <template #default>
      <template v-if="round == 0">
        <p>
          <font-awesome-icon v-bind="iconAttr[checkInStatus]" />
          {{ $t('message.checkPlayer', { players: $tc('unit.player', players.length), capacity: maxPlayer }) }}
        </p>
      </template>
      <template v-if="!isDenied">
        <p>
          {{ $t('message.confirm', { n: round + 1 }) }}
        </p>
        <skip-dialog-input v-model="skipDialog">
        </skip-dialog-input>
      </template>
    </template>

    <template #footer>
      <button class="btn btn-secondary" data-dismiss="modal">{{ $t('label.back') }}</button>
      <button v-if="!isDenied" class="btn btn-primary" data-dismiss="modal" @click="clickProceed">{{ $t('label.proceed') }}</button>
    </template>
  </base-modal>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapGetters } = createNamespacedHelpers('event')
import BaseModal from '@/components/BaseModal.vue'
import SkipDialogInput from '@/components/SkipDialogInput.vue'
import { iconAttrData, dialogSkip } from '@/mixins'
import { ThreeSignal } from '@/utils/constraints'

export default {
  name: "ConfirmRoundAdvanceModal",
  components: {
    BaseModal,
    SkipDialogInput,
  },
  mixins: [ iconAttrData, dialogSkip ],
  props: ["id"],
  computed: {
    ...mapState({
      minPlayer: state => state.rule.minPlayer,
      maxPlayer: state => state.rule.maxPlayer,
      players: state => state.player.list,
      round: state => state.round.count,
    }),
    ...mapGetters({
      checkInThreeSignal: 'checkInThreeSignal'
    }),
    checkInStatus() {
      switch(this.checkInThreeSignal) {
        case ThreeSignal.OK:
          return 'success'
        case ThreeSignal.WARN:
          return 'warning'
        default:
          return 'danger'
      }
    },
    isDenied() {
      return [this.checkInStatus].includes("danger")
    },
  },
  methods: {
    clickProceed() {
      if(this.skipDialog) {
        this.enableSkipDialog('skipRoundAdvance')
      }
      this.$emit('confirm')
    },
  },
}
</script>
<i18n>
{
  "en": {
    "message": {
      "checkPlayer": "{players} checked in; the capacity is {capacity}.",
      "confirm": "Are you sure to start Round {n}?"
    }
  },
  "ja": {
    "message": {
      "checkPlayer": "{players}がチェックインしました。定員は{capacity}人です。",
      "confirm": "{n}回戦を開始してよろしいですか?"
    }
  }
}
</i18n>
