<template>
  <div class="container">
    <h3 class="mb-3">{{ $t('label.tables') }}</h3>
    <div class="playtables">
      <play-table class="playtables-table m-2 p-3" v-for="table in tables" :key="table.seq" :table="table" :is-manager="isManager" @gameset="showModal">
      </play-table>
    </div>
    <div v-if="tables.length == 0" class="alert alert-secondary my-2">
      {{ $t('message.noTable') }}
    </div>
    <div v-if="isManager">
      <button class="btn" :class="finishClass" @click="confirmFinish" v-if="isRunning">{{ $t('label.roundOver') }}</button>
      <button class="btn btn-primary" @click="confirmAdvance" v-if="isPending && round < maxRound">{{ $t(advanceLabelPath) }}</button>
    </div>
    <div v-if="nodeEnv === 'development'">
      <hr>
      <p>Develop only</p>
      <div>
        <button class="btn btm-sm btn-outline-secondary" @click="generateTables">matching</button>
      </div>
    </div>
    <input-result-modal id="inputResult" :table="resultModalTableObj" @decideresult="saveResult" />
    <confirm-round-advance-modal id="confirmRoundAdvance" @confirm="advanceRound" />
    <confirm-round-over-modal id="confirmRoundOver" @confirm="finishRound" />
  </div>
</template>

<script>
// @ is an alias to /src
import $ from 'jquery'
import PlayTable from '@/components/PlayTable.vue'
import InputResultModal from '@/components/InputResultModal.vue'
import ConfirmRoundAdvanceModal from '@/components/ConfirmRoundAdvanceModal.vue'
import ConfirmRoundOverModal from '@/components/ConfirmRoundOverModal.vue'
import { RoundStatus, ThreeSignal } from '@/utils/constraints.js'
import { mapState, createNamespacedHelpers } from 'vuex'
const { mapGetters, mapActions } = createNamespacedHelpers('event')

export default {
  name: 'Tables',
  components: {
    PlayTable,
    InputResultModal,
    ConfirmRoundAdvanceModal,
    ConfirmRoundOverModal,
  },
  data() {
    return {
      resultModalTableObj: undefined,
      nodeEnv: process.env.NODE_ENV,
    }
  },
  computed: {
    ...mapState('event', {
      tables: state => state.table.list,
      roundStatus: state => state.round.status,
      round: state => state.round.count,
      maxRound: state => state.rule.maxRound,
    }),
    ...mapState({
      skipRoundOverSafety: state => state.config.skipRoundOverSafety,
      skipRoundAdvance: state => state.config.skipRoundAdvance,
    }),
    ...mapGetters([
      'processingCount',
      'checkInThreeSignal',
    ]),
    isPending() {
      return this.roundStatus === RoundStatus.PENDING
    },
    isRunning() {
      return this.roundStatus === RoundStatus.RUNNING
    },
    advanceLabelPath() {
      return this.round > 0 ? 'label.nextRound' : 'label.startRound'
    },
    finishClass() {
      let isFinish = this.tables.every(t => t.result && (t.result.isDraw || t.result.winner))
      return {
        'btn-danger': isFinish,
        'btn-outline-danger': !isFinish,
      }
    },
    isManager() {
      return this.$route.matched.some(r => r.meta.isWritable)
    },
  },
  methods: {
    showModal(tableSeq) {
      this.resultModalTableObj = this.tables.find(t => t.seq === tableSeq)
      $('#inputResult').modal('show')
    },
    confirmAdvance() {
      if(this.skipRoundAdvance && (this.round > 0 || this.checkInThreeSignal != ThreeSignal.BAD)) {
        this.advanceRound()
      } else {
        $('#confirmRoundAdvance').modal('show')
      }
    },
    confirmFinish() {
      if(this.skipRoundOverSafety && this.processingCount == 0) {
        this.finishRound()
      } else {
        $('#confirmRoundOver').modal('show')
      }
    },
    ...mapActions({
      generateTables: 'makePlayerMatches',
      finishRound: 'finishRound',
      advanceRound: 'advanceRound',
    }),
    saveResult(result) {
      this.$store.dispatch('event/saveTableResult', result)
    },
  }
}
</script>
<style scoped>
.playtables {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1em;
  grid-auto-rows: minmax(120px, auto)
}

@media (min-width: 768px) {
  .playtables {
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
<i18n>
{
  "en": {
    "label": {
      "tables": "Tables",
      "roundOver": "Round Over",
      "startRound": "Start Round",
      "nextRound": "Next Round"
    },
    "message": {
      "noTable": "No tables"
    }
  },
  "ja": {
    "label": {
      "tables": "試合卓一覧",
      "roundOver": "試合終了",
      "startRound": "試合開始",
      "nextRound": "試合開始"
    },
    "message": {
      "noTable": "卓はありません"
    }
  }
}
</i18n>
