<template>
  <base-modal :id="id">
    <template #header>
      <h4 class="modal-title">{{ $t('label.inputResult') }}</h4>
    </template>
    <template #default>
      <div class="input-grid">
        <!-- begin players row -->
        <div class="playername">{{ table.players[0] | playerLabel }}</div>
        <div class="versus">vs</div>
        <div class="playername">{{ table.players[1] | playerLabel }}</div>
        <!-- end players row-->

        <!-- begin buttons -->
        <div id="player1Result"><toggle-button :is-on="playerWin[0]" :labels="[$t('label.lose'), $t('label.win')]" class="btn-player" :disabled="isDraw" @click="toggleWin(0)"></toggle-button></div>
        <div id="player2Result"><toggle-button :is-on="playerWin[1]" :labels="[$t('label.lose'), $t('label.win')]" class="btn-player" :disabled="isDraw" @click="toggleWin(1)"></toggle-button></div>
        <div id="drawButton">
          <button class="btn" :class="drawClass" @click="toggleData('isDraw')">{{ $t('label.draw') }}</button>
        </div>
        <!-- end buttons -->
      </div>
    </template>
    <template #footer>
      <button class="btn btn-secondary" data-dismiss="modal">{{ $t('label.cancel') }}</button>
      <button class="btn btn-primary" data-dismiss="modal" @click="$emit('decideresult', returnResult)">{{ $t('label.decide') }}</button>
    </template>
  </base-modal>
</template>
<script>
import $ from 'jquery'
import BaseModal from '@/components/BaseModal.vue'
import ToggleButton from '@/components/ToggleButton.vue'

export default {
  name: "InputResultModal",
  components: {
    ToggleButton,
    BaseModal,
  },
  props: {
    id: {
      type: String,
      default: "inputResult"
    },
    table: {
      type: Object,
      default: () => ({
        seq: 0,
        players: [
          { seq: 0, name: "player 1" },
          { seq: 0, name: "player 2" },
        ]
      })
    }
  },
  data() {
    return {
      playerWin: [false, false],
      isDraw: false
    }
  },
  computed: {
    idSelector() {
      return $('#' + this.id)
    },
    drawClass() {
      return this.isDraw ? 'btn-warning' : 'btn-outline-secondary'
    },
    returnResult() {
      let winner = this.table.players.find((_, i) => this.playerWin[i])
      return {
        tableSeq: this.table.seq,
        winner: winner ? winner.seq : null,
        isDraw: this.isDraw,
      }
    },
  },
  watch: {
    table() {
      this.playerWin = this.table.players.map(e => this.table.result && (e.seq === this.table.result.winner))
      this.isDraw = this.table.result && this.table.result.isDraw
    },
  },
  filters: {
    playerLabel(player) {
      return `[${player.seq}] ${player.name}`
    },
  },
  methods: {
    show() {
      this.idSelector.modal()
    },
    toggleWin(i) {
      this.playerWin.splice(i, 1, !this.playerWin[i])
    },
    toggleData(k) {
      this[k] = !this[k]
    },
  },
}
</script>
<style>
.btn-warning {
  font-weight: bold;
}

.input-grid .btn {
  transition: none;
}

.input-grid {
  display: grid;
  grid-template-columns: 1fr 5em 1fr;
  grid-row-gap: 0.8em;
  text-align: center;
}

.playername {
  font-size: 1.6em;
  font-weight: bold;
}

.versus {
  font-size: 1.6em;
}

.input-grid #player1Result {
  grid-row: 2;
  grid-column: 1;
}

.input-grid #player2Result {
  grid-row: 2;
  grid-column: 3;
}

.input-grid #drawButton {
  grid-row: 3;
  grid-column: 1 / end;
}

.input-grid .btn-player {
  width: 4em;
}
</style>
<i18n>
{
  "en": {
    "label": {
      "inputResult": "Input result"
    }
  },
  "ja": {
    "label": {
      "inputResult": "結果を入力"
    }
  }
}
</i18n>
